import moment from "moment";

export default {
  formatNr(formatString, nr) {
    const dateStart = formatString.indexOf("{");
    const dateEnd = formatString.indexOf("}", dateStart);
    const dateString = moment(new Date()).format(
      formatString.substring(dateStart + 1, dateEnd)
    );

    formatString = formatString.replace(
      formatString.substring(dateStart, dateEnd + 1),
      dateString
    );
    const lfdnrStart = formatString.indexOf("%n");
    const lfdnrEnd = formatString.indexOf("%", lfdnrStart + 1);
    const lfdnr = formatString.substring(lfdnrStart, lfdnrEnd);

    let nextLfdnr = nr;
    let lfdnrFormat = 4;
    if (lfdnr.length > 2) {
      try {
        lfdnrFormat = Number.parseInt(
          formatString.substring(lfdnrStart + 2, lfdnrEnd)
        );
      } catch (e) {
        lfdnrFormat = 4;
      }

      const prefixLen = lfdnrFormat - String(nr).length;
      if (prefixLen > 0) {
        nextLfdnr = "";
        for (let n = 0; n < prefixLen; n++) {
          nextLfdnr += "0";
        }
        nextLfdnr += nr;
      }
    }

    formatString = formatString.replace(
      formatString.substring(lfdnrStart, lfdnrEnd + 1),
      nextLfdnr
    );

    return formatString;
  },
};
