<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12">
        <v-card dense>
          <v-card-text>
            <v-form
              name="mandantenform"
              @submit.prevent="save"
              v-model="mandant.formValid"
            >
              <v-container>
                <h2>Firmendetails</h2>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-file-input
                      accept=".png, .jpg"
                      name="logo"
                      @change="onImageFileChanged"
                    />
                    <div style="text-align: center">
                      <v-progress-linear
                        v-if="upload"
                        v-model="uploadProcess"
                        height="25"
                        color="blue"
                      >
                        <strong>{{ Math.ceil(uploadProcess) }}%</strong>
                      </v-progress-linear>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-img
                      :src="logoUrl"
                      max-height="100px"
                      height="100px"
                      contain
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      label="Firmenname"
                      name="firmenname"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.firmenname"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Vorname"
                      name="firstname"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.firstname"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Nachname"
                      name="lastname"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.lastname"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      label="Adresse"
                      name="adresse"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.adresse"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="3">
                    <v-select
                      label="Land"
                      name="land"
                      :items="countryList"
                      item-text="nameDE"
                      item-value="code"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.land"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="3">
                    <v-text-field
                      label="PLZ"
                      name="plz"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.plz"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Ort"
                      name="ort"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.ort"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Email"
                      name="email"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.email"
                      required
                      dense
                      :rules="emailRules"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Web"
                      name="web"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.web"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Telefon"
                      name="telefon"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.telefon"
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Fax"
                      name="fax"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.fax"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h2>Gewerbe- und Steuernummern</h2>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Handelsgericht"
                      name="handelsgericht"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.handelsgericht"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Firmenbuchnummer"
                      name="firmenbuchnr"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.firmenbuchnr"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Steuernummer"
                      name="steuernr"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.steuernr"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="UID"
                      name="uid"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.uid"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="EORI"
                      name="eori"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.eori"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                <h2>Bank- und Kontoinformationen</h2>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Bank"
                      name="bank"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.bank"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Kontoinhaber"
                      name="inhaber"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.inhaber"
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Kontonr"
                      name="kontonr"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.kontonr"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="BLZ"
                      name="blz"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.blz"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="IBAN"
                      name="iban"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.iban"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="BIC"
                      name="bic"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.bic"
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h2>Beginn Geschäftsjahr</h2>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="gfDateMenu"
                      v-model="gfDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="mandant.gfDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="validDateFormatted"
                          label="Beginn Geschäftsjahr"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        dense
                        v-model="mandant.gfDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="gfDateMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.gfDateMenu.save(mandant.gfDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-divider />
                <h2>Datumsvorgaben</h2>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      label="Angebot Gültigkeitsdauer in Tagen"
                      value="30"
                      name="defaultOfferValidDays"
                      color="purple"
                      v-model="mandant.defaultOfferValidDays"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      label="Zahlungsziel in Tagen"
                      value="14"
                      name="defaultPayDays"
                      color="purple"
                      v-model="mandant.defaultPayDays"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                <h2>Nummernkreise</h2>
                <h3>Festlegen der Startnummern</h3>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Start Angebote"
                      name="offerStart"
                      type="number"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.offer.start"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Start Auftragsbestätigung"
                      name="confirmationStart"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.confirmation.start"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Start Lieferscheine"
                      name="deliveryStart"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.delivery.start"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Start Rechnung"
                      name="billStart"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.bill.start"
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>

                <v-alert border="left" colored-border color="purple">
                  <h3>Nummernformate festlegen</h3>
                  <p>
                    Im Format können Datumsangaben mit den Formatangaben von
                    <a
                      href="https://momentjs.com/docs/#/displaying/"
                      target="_blank"
                      >momentjs</a
                    >
                    angegeben werden.
                    <br />
                    Z.B.: {YYYY} für die vierstellige Jahreszahl.
                    <br />Die vom System fortlaufende Nr. kann mit der Variable
                    %n angeben werden, mit der angefügte Zahl dahinter (ohne
                    Leerzeichen) kann die Darstellungslänge der fortlaufenden
                    Zahl bestimmt werden.
                  </p>
                </v-alert>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Format Angebote"
                      name="offerFormat"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.offer.format"
                      :rules="requiredRule"
                    ></v-text-field>
                    <v-chip small>
                      Vorschau:
                      {{ previewOffer }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Format Auftragsbestätigung"
                      name="confirmationFormat"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.confirmation.format"
                      :rules="requiredRule"
                    />
                    <v-chip small>
                      Vorschau:
                      {{ previewConfirmation }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Format Lieferscheine"
                      name="deliveryFormat"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.delivery.format"
                      :rules="requiredRule"
                    />
                    <v-chip small>
                      Vorschau:
                      {{ previewDelivery }}
                    </v-chip>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Format Rechnung"
                      name="billFormat"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="mandant.nummernkreise.bill.format"
                      :rules="requiredRule"
                    />
                    <v-chip small>
                      Vorschau:
                      {{ previewBill }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-divider />
                <h2>PDF Vorlagen auswählen</h2>
                <v-row>
                  <v-col cols="12" sm="3">
                    <PDFTemplateSelectbox
                      label="Vorlage Angebot"
                      name="pdfOffer"
                      type="offer"
                      v-model="mandant.pdfTemplates.offerTemplate"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <PDFTemplateSelectbox
                      label="Vorlage AB"
                      name="pdfConfirmation"
                      type="confirmation"
                      v-model="mandant.pdfTemplates.confirmationTemplate"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <PDFTemplateSelectbox
                      label="Vorlage Lieferschein"
                      name="pdfDelivery"
                      type="delivery"
                      v-model="mandant.pdfTemplates.deliveryTemplate"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <PDFTemplateSelectbox
                      label="Vorlage Rechnung"
                      name="pdfBill"
                      type="bill"
                      v-model="mandant.pdfTemplates.billTemplate"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h2>Mail Einstellungen</h2>
                <h3>Absender</h3>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Absendername"
                      name="mailnamefrom"
                      type="text"
                      v-model="mandant.mailSettings.mailnamefrom"
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Absenderemail"
                      name="mailadressfrom"
                      type="text"
                      v-model="mandant.mailSettings.mailadressfrom"
                      :rules="emailRules"
                    />
                  </v-col>
                </v-row>
                <h3>SMTP Server Einstellungen</h3>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-checkbox
                      label="Externen Mailserver verwenden"
                      v-model="mandant.mailSettings.useexternalsmtp"
                      color="green"
                    />
                  </v-col>
                </v-row>
                <v-row v-show="mandant.mailSettings.useexternalsmtp">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="SMTP Mailserver"
                      name="smtpserver"
                      type="text"
                      v-model="mandant.mailSettings.smtpserver"
                    />
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field
                      label="SMTP Port"
                      name="smtpport"
                      type="number"
                      v-model="mandant.mailSettings.smtpport"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      label="TLS aktivieren"
                      v-model="mandant.mailSettings.smtptls"
                      color="green"
                    />
                  </v-col>
                </v-row>
                <v-row v-show="mandant.mailSettings.useexternalsmtp">
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      label="SMTP Authentifizierung"
                      v-model="mandant.mailSettings.smtpauth"
                      color="green"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-show="mandant.mailSettings.smtpauth"
                      label="SMTP User"
                      name="smtpuser"
                      type="text"
                      v-model="mandant.mailSettings.smtpuser"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-show="mandant.mailSettings.smtpauth"
                      label="SMTP Passwort"
                      name="smtppass"
                      type="password"
                      v-model="mandant.mailSettings.smtppass"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h2>FinanzOnline Webservice Einstellungen</h2>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      type="text"
                      label="Teilnehmer ID"
                      clearable
                      name="tid"
                      v-model="mandant.uidcheckSettings.tid"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      type="text"
                      label="Benutzer ID"
                      clearable
                      name="benid"
                      v-model="mandant.uidcheckSettings.benid"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      type="password"
                      label="PIN"
                      clearable
                      name="pin"
                      v-model="mandant.uidcheckSettings.pin"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ path: '/mandanten' }"
              color="error"
              type="button"
              name="cancel"
              >Abbrechen</v-btn
            >
            <v-spacer />
            <v-btn
              @click="save"
              color="success"
              name="submit"
              type="submit"
              :disabled="!formValid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataService from "@/services/MandantenService.js";
import formatNummernkreise from "@/helpers/formatNummernkreise.js";
import PDFTemplateSelectbox from "@/components/pdf/TemplateSelectbox";
import moment from "moment";
//import { serialize } from "object-to-formdata";

export default {
  name: "MandantenForm",
  components: { PDFTemplateSelectbox },
  data: () => ({
    logo: undefined,
    uploadProcess: 0,
    upload: false,
    useexternalsmtp: false,

    gfDateMenu: false,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid",
    ],
    formValid: true,
  }),

  methods: {
    onImageFileChanged(image) {
      // console.log(image);
      if (image) {
        this.logo = image;
        this.uploadProcess = 0;
        this.upload = false;
        /* DataService.upload(this.logo, (event) => {
          this.uploadProcess = Math.round((100 * event.loaded) / event.total);
          if (this.uploadProcess == 100) {
            this.upload = false;
          }
        });
        */
        //document.getElementById("image_id").attr("src", URL.createObjectURL(e)); // jQuery selector
      }
    },
    save() {
      if (!this.logo) {
        this.$notify({
          group: "dataline",
          type: "info",
          title: "Mandant ohne Logo",
          text: "Mandant besitzt kein Logo",
        });
      }

      DataService.save({
        id: this.mandant._id,
        data: this.mandant,
        logo: this.logo,
      })
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "mandanten" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Mandant gespeichert",
            text: "Mandant erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    },
  },
  computed: {
    countryList() {
      return this.$store.getters["countries/getCountryList"];
    },
    previewOffer() {
      return formatNummernkreise.formatNr(
        this.mandant.nummernkreise.offer.format,
        this.mandant.nummernkreise.offer.start
      );
    },
    previewConfirmation() {
      return formatNummernkreise.formatNr(
        this.mandant.nummernkreise.confirmation.format,
        this.mandant.nummernkreise.confirmation.start
      );
    },
    previewDelivery() {
      return formatNummernkreise.formatNr(
        this.mandant.nummernkreise.delivery.format,
        this.mandant.nummernkreise.delivery.start
      );
    },
    previewBill() {
      return formatNummernkreise.formatNr(
        this.mandant.nummernkreise.bill.format,
        this.mandant.nummernkreise.bill.start
      );
    },
    validDateFormatted() {
      return this.mandant.gfDate
        ? moment(this.mandant.gfDate).format("DD.MM")
        : "";
    },
    mandant() {
      let current = this.$store.getters["mandant/getCurrent"];
      return current;
    },
    logoUrl() {
      const url = process.env.VUE_APP_API_URL + this.mandant.logo;
      return url;
    },
  },
  created() {
    let id = this.$route.params.id;

    this.$store
      .dispatch("pdfTemplate/fetchTemplateList", this.type)
      .then(() => {
        // this.value = this.mandantenList[0].value;
        //this.value = result.data.data[0]._id;
      })
      .catch((error) => {
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message,
          });
        }
      });

    if (!id) {
      this.$store.dispatch("mandant/newMandant").then(() => {});
    } else {
      this.$store
        .dispatch("mandant/fetchMandant", id)
        //DataService.get(id)
        .then(() => {})
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    }
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0px !important;
}
</style>
