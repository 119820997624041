<template>
  <div>
    <v-select
      :label="label"
      name="pdftemplate"
      ref="templateSelectbox"
      :items="templateList"
      :color="color"
      :clearable="false"
      :value="value"
      :rules="rules"
      :required="required"
      :dense="dense"
      @change="updateValue"
    />
  </div>
</template>

<script>
export default {
  props: ["color", "label", "value", "required", "rules", "dense", "type"],
  data: () => ({
    selected: null
  }),
  computed: {
    templateList: {
      get() {
        let pdflist = [];
        let templateList = [];
        if (this.type == "offer") {
          templateList = this.$store.getters[
            "pdfTemplate/getOfferTemplateList"
          ];
        } else if (this.type == "confirmation") {
          templateList = this.$store.getters[
            "pdfTemplate/getConfirmationTemplateList"
          ];
        } else if (this.type == "delivery") {
          templateList = this.$store.getters[
            "pdfTemplate/getDeliveryTemplateList"
          ];
        } else if (this.type == "bill") {
          templateList = this.$store.getters["pdfTemplate/getBillTemplateList"];
        }

        templateList.forEach((item) => {
          pdflist.push({ text: item.name, value: item._id });
        });

        return pdflist;
      },
      set() {}
    }
  },
  methods: {
    updateValue(value) {
      this.selected = value;
      this.$emit("input", this.selected);
    }
  },
  created() {}
};
</script>
